import { Link } from "gatsby";
import React, { useState } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header>
      <div className='flex flex-wrap items-center justify-between  py-4 md:py-8 xxl:py-16 px-8 lg:px-16 xxl:px-32'>
        <Link to='/'>
          <p className='text-2xl sm:text-2xl xxl:text-4xl text-gray-900 font-semibold tracking-widest'>
            bytewrap
          </p>
        </Link>

        <button
          className='flex items-center px-3 py-2 text-gray-900 border border-gray-900 rounded md:hidden'
          onClick={() => toggleExpansion(!isExpanded)}>
          <svg
            className='w-3 h-3 fill-current'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'>
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>
        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:flex md:items-center w-full md:w-auto `}>
          {/* <Link
            to='/blog'
            className='block text-xl lg:text-2xl xxl:text-3xl text-gray-900 no-underline md:inline-block md:mt-0 md:ml-8 hover:underline cursor-pointer'>
            blog
          </Link> */}
          {[
            {
              route: `#work-sec`,
              title: `work`,
            },
            {
              route: `#contact-sec`,
              title: `contact`,
            },
          ].map((link, index) => (
            <p
              className='block mt-4  text-xl lg:text-2xl xxl:text-3xl text-gray-900 no-underline md:inline-block md:mt-0 md:ml-8 hover:underline cursor-pointer'
              key={index}
              onClick={() => {
                scrollTo(link.route);
              }}>
              {link.title}
            </p>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
