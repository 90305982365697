import React from "react";
import PropTypes from "prop-types";

export default function Check({ isChecked, onClick, title }) {
  if (isChecked) {
    return (
      <div
        onClick={() => onClick(title)}
        className='mb-8 bg-white mr-10  w-24 rounded-full h-8 cursor-pointer flex justify-center items-center'>
        <p className='m-0 text-lg text-center sm:text-sm md:text-md lg:text-lg'>
          {title}
        </p>
      </div>
    );
  }

  return (
    <div
      onClick={() => onClick(title)}
      style={{ backgroundColor: "#c4c4c4" }}
      className='mb-8 mr-10 w-24 rounded-full h-8 cursor-pointer flex justify-center items-center'>
      <p className='m-0 text-lg text-center sm:text-sm md:text-md lg:text-lg'>
        {title}
      </p>
    </div>
  );
}

Check.propTypes = {
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};
