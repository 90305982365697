import React from "react";
import PropTypes from "prop-types";
import success from "../images/success.png";

function Modal({ show, onToggle }) {
  if (!show) {
    return null;
  }
  return (
    <div
      className='fixed inset-0 z-50'
      style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
      <div className='flex w-full h-full justify-center items-center'>
        <div
          className='w-72 lg:h-72 bg-white rounded-lg flex flex-col justify-between items-center p-4 '
          style={{ height: "22rem", width: "23rem" }}>
          <img src={success} alt='thankyou' className='w-24 h-24' />
          <p className='m-0 text-lg text-center xl:text-xl'>
            Thanks for showing interest in us, soon you will be contact with
            sales team.
          </p>
          <button
            className='p-2 bg-yellow-400 outline-none w-20 rounded-md'
            onClick={onToggle}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default Modal;
