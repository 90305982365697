import React, { Component } from "react";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import Check from "./check";
import axios from "axios";
import gif from "../images/loader.gif";
import Modal from "./modal";

class Footer extends Component {
  state = {
    email: "",
    name: "",
    budget: "<10K",
    loading: false,
    modal: false,
  };

  onBudgetClick = (value) => {
    this.setState({ budget: value });
  };

  handleName = (e) => {
    this.setState({ name: e.target.value });
  };

  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.sendEmail();
  };

  sendEmail = async () => {
    const { email, name, budget } = this.state;

    try {
      await axios({
        url: "https://bytewrap.com/.netlify/functions/sendEmail",
        method: "POST",
        data: { email, name, budget },
      });
      this.setState({ loading: false, email: "", name: "", modal: true });
    } catch (e) {
      this.setState({ loading: false });
      console.log("Done testing", e);
    }
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const { loading, modal, email, name } = this.state;
    return (
      <footer className='bg-gray-900 px-8 lg:px-16 xxl:px-32 pt-8 md:pt-18 pb-8 flex mt-16 xl:mt-32 flex-col lg:flex-row md:flex-col sm:flex-col'>
        
      <div class="flex-1 flex flex-col mb-8 lg:mb-0 lg:flex-col lg:items-baseline justify-between">
      <h1 id="contact-sec" class="flex text-white">contact</h1>
      <h2 id="contact-sec" class="flex text-white">adi@bytewrap.com</h2>
      <h2 id="contact-sec" class="flex text-white">Aditya Kandari</h2>
      <h2 id="contact-sec" class="flex text-white">Bytewrap Technologies (OPC) Pvt. Ltd.</h2>
      <h2 id="contact-sec" class="flex text-white">BEYOND TIME LABS FZ LLC</h2>
      <p class="text-xs lg:text-base xxl:text-xl text-white pt-8 sm:pt-0 lg:text-left ">©bytewrap</p>
      </div>

        <div className='flex flex-col flex-1	items-center  items-baseline'>
          <p className='text-white text-xl'> tell us about your project</p>
          <form
            className='flex-1 w-full lg:w-4/5 mt-4'
            onSubmit={this.handleSubmit}>
            <div className='flex flex-col lg:flex-row md:flex-row sm: flex-row'>
              <input
                value={name}
                placeholder='name'
                required
                type='text'
                className='border-b-2 bg-transparent mr-10 h-10 w-1/2 md:w-1/3 lg:w-1/3 text-white outline-none mb-4 lg:mb-0 md:mb-0'
                onChange={this.handleName}
              />
              <input
                value={email}
                placeholder='email address'
                type='email'
                required
                className='border-b-2 bg-transparent h-10 w-1/2 text-white outline-none'
                onChange={this.handleEmail}
              />
            </div>
            <p className='text-white text-lg mt-6 mb-4'>budget in USD</p>
            <div className='flex mb-2 flex-wrap'>
              <Check
                onClick={this.onBudgetClick}
                title='<10K'
                isChecked={this.state.budget === "<10K"}
              />
              <Check
                onClick={this.onBudgetClick}
                title='10K - 20K'
                isChecked={this.state.budget === "10K - 20K"}
              />
              <Check
                onClick={this.onBudgetClick}
                title='20K+'
                isChecked={this.state.budget === "20K+"}
              />
            </div>

            <button
              className='bg-yellow-500 w-32 h-12 rounded-lg font-bold flex justify-center items-center'
              type='submit'>
              {loading ? (
                <img src={gif} style={{ height: 30 }} alt='loading...' />
              ) : (
                <p className='m-0 text-xl'>Submit</p>
              )}
            </button>
          </form>
        </div>
        <Modal show={modal} onToggle={this.toggleModal} />
      </footer>
    );
  }
}

export default Footer;
